import { createRouter, createWebHashHistory } from 'vue-router';
import { useCookies } from 'vue3-cookies';

const { cookies } = useCookies();

document.title = 'Immersiveat Admin Panel';

function lazyLoad(view) {
    return () => import(`../views/${view}.vue`);
}

const checkAuth = (to, from, next) => {
    const isAuthenticated = cookies.get('token');
    if (to.name === 'login' && isAuthenticated) {
        next('/');
    }
    if (!isAuthenticated) {
        next('/login');
    } else {
        next();
    }
};

const routes = [
    {
        path: '/',
        redirect: to => {
            return { name: 'projects-list' };
        },
    },
    {
        path: '/overview-main',
        name: 'overviewMain',
        beforeEnter: checkAuth,
        meta: {
            layout: 'default',
            title: 'overview',
            mainSidebar: true,
            requiresAuth: true,
        },
        component: lazyLoad('mainOverview/DashboardOverview'),
    },
    {
        path: '/auth',

        children: [
            {
                path: '/login',
                name: 'login',
                component: lazyLoad('auth/LoginView'),
            },
            {
                path: '/forgot-pass',
                name: 'forgot-pass',
                component: lazyLoad('auth/ForgotPassView'),
            },
        ],
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        meta: {
            layout: 'default',
            mainSidebar: true,
            title: 'NotFound',
            requiresAuth: true,
        },
        component: lazyLoad('404/NotFound'),
    },
    {
        path: '/overview',
        name: 'overview',
        beforeEnter: checkAuth,
        meta: { layout: 'default', title: 'overview', requiresAuth: true },
        component: lazyLoad('overview/DashboardOverview'),
    },
    {
        path: '/test',
        name: 'test',
        beforeEnter: checkAuth,
        meta: { layout: 'default', title: 'test', requiresAuth: true },
        component: lazyLoad('test'),
    },
    {
        path: '/project-content',
        name: 'projectContent',
        beforeEnter: checkAuth,
        meta: {
            layout: 'default',
            title: 'Project Content',
            requiresAuth: true,
        },
        redirect: to => {
            return { name: 'suites' };
        },
        children: [
            {
                path: 'models',
                beforeEnter: checkAuth,
                meta: {
                    layout: 'default',
                    requiresAuth: true,
                },
                children: [
                    {
                        path: '',
                        name: 'models',
                        component: lazyLoad('projectContent/models/ModelsView'),
                    },
                    {
                        path: ':modelId',
                        name: 'model',
                        component: lazyLoad('projectContent/models/ModelView'),
                    },
                    {
                        path: ':modelId/edit',
                        name: 'editModel',
                        component: lazyLoad(
                            'projectContent/models/UpsertModelView'
                        ),
                    },
                    {
                        path: 'create',
                        name: 'create-model',
                        component: lazyLoad(
                            'projectContent/models/UpsertModelView'
                        ),
                    },
                ],
            },
            {
                path: 'suites',
                beforeEnter: checkAuth,
                meta: {
                    layout: 'default',
                    requiresAuth: true,
                },
                children: [
                    {
                        path: '',
                        name: 'suites',
                        component: lazyLoad('projectContent/suites/SuitesView'),
                    },
                    {
                        path: ':suiteId',
                        name: 'suite',
                        component: lazyLoad('projectContent/suites/SuiteView'),
                    },
                    {
                        path: ':suiteId/edit',
                        name: 'editSuite',
                        component: lazyLoad(
                            'projectContent/suites/UpsertSuiteView'
                        ),
                    },
                    {
                        path: 'create',
                        name: 'create-suite',
                        component: lazyLoad(
                            'projectContent/suites/UpsertSuiteView'
                        ),
                    },
                ],
            },
            {
                path: 'gallery-categories',
                beforeEnter: checkAuth,
                meta: {
                    layout: 'default',
                    requiresAuth: true,
                },
                children: [
                    {
                        path: '',
                        name: 'gallery',
                        meta: {
                            title: 'Gallery',
                        },
                        component: lazyLoad(
                            'projectContent/gallery/GalleryView'
                        ),
                    },
                    {
                        path: ':galleryId/renderings',
                        name: 'renderings',
                        meta: {
                            title: 'Renderings',
                        },
                        component: lazyLoad(
                            'projectContent/gallery/RenderingsView'
                        ),
                    },
                    {
                        path: ':galleryId/renderings/:itemId/edit',
                        name: 'editRenderingItem',
                        meta: {
                            title: 'Edit Rendering Item',
                        },
                        component: lazyLoad(
                            'projectContent/gallery/UpsertRenderingItemView'
                        ),
                    },
                    {
                        path: ':galleryId/renderings/create',
                        name: 'createRenderingItem',
                        component: lazyLoad(
                            'projectContent/gallery/UpsertRenderingItemView'
                        ),
                        meta: {
                            title: 'Add New Item',
                        },
                    },
                ],
            },
            {
                path: 'neighbourhood',
                beforeEnter: checkAuth,
                meta: {
                    layout: 'default',
                    title: 'Neighbourhood',
                    requiresAuth: true,
                },
                children: [
                    {
                        path: '',
                        name: 'neighbourhood',
                        beforeEnter: checkAuth,
                        meta: {
                            title: 'Neighbourhood',
                            requiresAuth: true,
                        },
                        component: lazyLoad(
                            'projectContent/neighbourhood/Neighbourhood'
                        ),
                    },
                    {
                        path: 'add-location',
                        name: 'add-location',
                        beforeEnter: checkAuth,
                        component: lazyLoad(
                            'projectContent/neighbourhood/AddLocation'
                        ),
                        meta: {
                            title: 'Add new location',
                        },
                    },
                    {
                        path: 'view-location',
                        name: 'view-location',
                        beforeEnter: checkAuth,
                        component: lazyLoad(
                            'projectContent/neighbourhood/ViewNeighbourhood'
                        ),
                        meta: {
                            title: 'View location',
                        },
                    },
                ],
            },
            {
                path: 'builder-stories',
                beforeEnter: checkAuth,
                meta: {
                    layout: 'default',
                    title: 'Builder Stories',
                    requiresAuth: true,
                },
                children: [
                    {
                        path: '',
                        name: 'builder-stories',
                        beforeEnter: checkAuth,
                        meta: {
                            title: 'Builder Stories',
                            requiresAuth: true,
                        },
                        component: lazyLoad(
                            'projectContent/builderStories/BuilderStories'
                        ),
                    },
                    {
                        path: 'add-builder',
                        name: 'add-builder',
                        beforeEnter: checkAuth,
                        component: lazyLoad(
                            'projectContent/builderStories/AddBuilder'
                        ),
                        meta: {
                            title: 'Add New Builder',
                        },
                    },
                    {
                        path: 'view-builder',
                        name: 'view-builder',
                        beforeEnter: checkAuth,
                        component: lazyLoad(
                            'projectContent/builderStories/ViewBuilder'
                        ),
                        meta: {
                            title: 'View Builder',
                        },
                    },
                ],
            },
            {
                path: 'amenities-map',
                beforeEnter: checkAuth,
                meta: {
                    layout: 'default',
                    title: 'Amenities Map',
                    requiresAuth: true,
                },
                children: [
                    {
                        path: '',
                        name: 'amenities-map',
                        beforeEnter: checkAuth,
                        meta: {
                            title: 'Amenities Map',
                            requiresAuth: true,
                        },
                        component: lazyLoad(
                            'projectContent/amenitiesMap/AmenitiesMap'
                        ),
                    },
                    {
                        path: 'add-pointer',
                        name: 'add-pointer',
                        beforeEnter: checkAuth,
                        component: lazyLoad(
                            'projectContent/amenitiesMap/AddPointer'
                        ),
                        meta: {
                            title: 'Add New Pointer',
                        },
                    },
                    {
                        path: 'view-pointer',
                        name: 'view-pointer',
                        beforeEnter: checkAuth,
                        component: lazyLoad(
                            'projectContent/amenitiesMap/ViewPointer'
                        ),
                        meta: {
                            title: 'View Pointer',
                        },
                    },
                ],
            },
        ],
    },
    {
        path: '/reporting',
        name: 'reporting',
        beforeEnter: checkAuth,
        meta: {
            layout: 'default',
            title: 'reporting',
            requiresAuth: true,
        },
        redirect: to => {
            return { name: 'RegistrationReport' };
        },
        children: [
            {
                path: 'Visual-Summaries',
                beforeEnter: checkAuth,
                meta: {
                    layout: 'default',
                    requiresAuth: true,
                },
                children: [
                    {
                        path: '',
                        name: 'VisualSummaries',
                        component: lazyLoad('reporting/VisualSummaries'),
                    },
                ],
            },
            {
                path: 'Download-Report',
                beforeEnter: checkAuth,
                meta: {
                    layout: 'default',
                    requiresAuth: true,
                },
                children: [
                    {
                        path: '',
                        name: 'DownloadReport',
                        component: lazyLoad('reporting/DownloadReport'),
                    },
                ],
            },
            {
                path: 'Registration-Report',
                beforeEnter: checkAuth,
                meta: {
                    layout: 'default',
                    requiresAuth: true,
                },
                children: [
                    {
                        path: '',
                        name: 'RegistrationReport',
                        component: lazyLoad('reporting/RegistrationReport'),
                    },
                ],
            },
            {
                path: 'Traffic-Report',
                beforeEnter: checkAuth,
                meta: {
                    layout: 'default',
                    requiresAuth: true,
                },
                children: [
                    {
                        path: '',
                        name: 'TrafficReport',
                        component: lazyLoad('reporting/TrafficReport'),
                    },
                ],
            },
        ],
    },
    {
        path: '/profile',
        beforeEnter: checkAuth,
        meta: {
            layout: 'default',
            title: 'Profile',
            mainSidebar: true,
            requiresAuth: true,
        },
        children: [
            {
                path: '',
                name: 'profile',
                meta: { title: 'Profile', mainSidebar: true },
                component: lazyLoad('profile/ProfileView'),
            },
            {
                path: 'edit-pass',
                name: 'edit-pass',
                meta: { title: 'Reset Password', mainSidebar: true },
                component: lazyLoad('profile/EditPassView'),
            },
        ],
    },
    {
        path: '/worksheets',
        beforeEnter: checkAuth,
        meta: { layout: 'default', title: 'Worksheets', requiresAuth: true },
        children: [
            {
                path: '',
                name: 'worksheets',
                meta: { title: 'worksheets' },
                component: lazyLoad('worksheets/WorksheetsView'),
            },
            {
                path: ':modelId',
                name: 'worksheetsFilter',
                meta: { title: 'worksheets' },
                component: lazyLoad('worksheets/WorksheetsView'),
            },
            {
                path: 'view-worksheets',
                name: 'view-worksheets',
                beforeEnter: checkAuth,
                component: lazyLoad('worksheets/ViewWorksheet'),
                meta: {
                    title: 'View Worksheet',
                },
            },
        ],
    },
    {
        path: '/allocation',
        beforeEnter: checkAuth,
        meta: { layout: 'default', title: 'allocation', requiresAuth: true },
        children: [
            {
                path: '',
                name: 'allocation',
                meta: { title: 'allocation' },
                component: lazyLoad('allocation/AllocationsView'),
            },
            {
                path: 'view-allocation',
                name: 'view-allocation',
                beforeEnter: checkAuth,
                component: lazyLoad('allocation/ViewAllocation'),
                meta: {
                    title: 'View Worksheet',
                },
            },
        ],
    },
    {
        path: '/inventory',
        beforeEnter: checkAuth,
        meta: { layout: 'default', title: 'Inventory', requiresAuth: true },
        children: [
            {
                path: '',
                name: 'inventory',
                meta: { title: 'Inventory' },
                component: lazyLoad('inventory/InventoryView'),
            },
            {
                path: 'view-inventory',
                name: 'view-inventory',
                beforeEnter: checkAuth,
                component: lazyLoad('inventory/ViewInventory'),
                meta: {
                    title: 'View inventory',
                },
            },
        ],
    },
    {
        path: '/projects-list',
        beforeEnter: checkAuth,
        name: 'projects-list',
        component: lazyLoad('projectsList/ProjectsList'),
        meta: {
            layout: 'default',
            mainSidebar: true,
            title: 'Projects list',
            requiresAuth: true,
        },
    },
    {
        path: '/sales-tracker',
        beforeEnter: checkAuth,
        meta: { layout: 'default', title: 'Sales Tracker', requiresAuth: true },
        children: [
            {
                path: '',
                name: 'sales-tracker',
                meta: { layout: 'default', title: 'Sales Tracker' },
                component: lazyLoad('salesTracker/SalesTracker'),
            },
            {
                path: 'view-package',
                name: 'view-package',
                meta: { layout: 'default', title: 'View Package' },
                component: lazyLoad('salesTracker/ViewPackage'),
            },
        ],
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;
