<template>
    <aside
        class="wrapper"
        :class="{
            open: isSidebarOpen,
            wrapper1: !$route.meta.mainSidebar,
            wrapper2: $route.meta.mainSidebar,
        }">
        <div>
            <nav
                class="main-side hide-scrollbar"
                :class="[showNav ? 'show-main-side' : 'hide-main-side']"
                @mouseover="mouseoverNav($route.meta.mainSidebar)"
                @mouseleave="showNav = false">
                <div
                    v-if="$route.meta.mainSidebar"
                    class="menu h-full flex flex-col items-start justify-start gap-0 pb-4">
                    <div
                        class="logo logo-aside text-white text-[18px] font-[700]">
                        <img
                            class="w-[30px] h-[30px]"
                            v-if="
                                store.user.organization &&
                                store.user.organization.aside_logo
                            "
                            :src="store.user.organization.aside_logo" />
                        <p
                            class="h-[30px]"
                            v-else>
                            logo
                        </p>
                    </div>
                    <div
                        class="flex flex-col items-start justify-between h-full w-full">
                        <div
                            class="flex flex-col items-start justify-start gap-2 w-full">
                            <router-link
                                v-if="hasActiveRoute('projects-list')"
                                to="/projects-list"
                                class="link flex gap-2"
                                :class="{
                                    'link-show-text': showNav,
                                    'w-full': !showNav,
                                }"
                                @click="closeSidebarAndAccordions">
                                <div class="icon">
                                    <IconHome
                                        class="dashboard-icon normal-icon" />
                                    <IconHomeActive
                                        class="dashboard-icon active-icon" />
                                </div>
                                <span
                                    class="text-white"
                                    v-if="showNav"
                                    >Home</span
                                >
                            </router-link>
                            <router-link
                                v-if="hasActiveRoute('overviewMain')"
                                to="/overview-main"
                                class="link flex gap-2"
                                :class="{
                                    'link-show-text': showNav,
                                    'w-full': !showNav,
                                }"
                                @click="closeSidebarAndAccordions">
                                <div class="icon">
                                    <IconGraph
                                        class="dashboard-icon normal-icon" />
                                    <IconGraphActive
                                        class="dashboard-icon active-icon" />
                                </div>
                                <span
                                    class="text-white"
                                    v-if="showNav">
                                    Overview
                                </span>
                            </router-link>
                            <router-link
                                v-if="hasActiveRoute('users')"
                                to="/home1"
                                class="link flex gap-2"
                                :class="{
                                    'link-show-text': showNav,
                                    'w-full': !showNav,
                                }"
                                @click="closeSidebarAndAccordions">
                                <div class="icon">
                                    <IconMainUsers
                                        class="dashboard-icon normal-icon" />
                                    <IconMainUsersActive
                                        class="dashboard-icon active-icon" />
                                </div>
                                <span
                                    class="text-white"
                                    v-if="showNav">
                                    Users
                                </span>
                            </router-link>
                            <router-link
                                v-if="hasActiveRoute('settings')"
                                to="/home2"
                                class="link flex gap-2"
                                :class="{
                                    'link-show-text': showNav,
                                    'w-full': !showNav,
                                }"
                                @click="closeSidebarAndAccordions">
                                <div class="icon">
                                    <IconSetting
                                        class="dashboard-icon normal-icon" />
                                    <IconSetting
                                        class="dashboard-icon active-icon" />
                                </div>
                                <span
                                    class="text-white"
                                    v-if="showNav">
                                    Setting
                                </span>
                            </router-link>
                        </div>
                        <button
                            class="logout link"
                            @click="openLogoutModal">
                            <IconLogout />
                            <span
                                class="text-white"
                                v-if="showNav"
                                >Logout</span
                            >
                        </button>
                    </div>
                </div>
                <div
                    v-else
                    class="menu h-full flex flex-col items-start justify-start gap-0 pb-4">
                    <div
                        class="logo logo-aside text-white text-[18px] font-[700]">
                        <img
                            class="w-[30px] h-[30px]"
                            v-if="
                                store.user.organization &&
                                store.user.organization.aside_logo
                            "
                            :src="store.user.organization.aside_logo" />
                        <p
                            class="h-[30px]"
                            v-else>
                            logo
                        </p>
                    </div>
                    <div
                        class="flex flex-col items-start justify-between h-full w-full">
                        <div
                            class="flex flex-col items-start justify-start gap-2 w-full">
                            <VDropdown
                                class="my-custom-theme"
                                :triggers="['hover', 'focus']"
                                placement="right"
                                popperClass="my-custom-theme"
                                :distance="6">
                                <div class="info cursor-pointer">
                                    <router-link
                                        v-if="hasActiveRoute('projects-list')"
                                        to="/projects-list"
                                        class="link flex gap-2"
                                        :class="{
                                            'link-show-text': showNav,
                                            'w-full': !showNav,
                                        }"
                                        @click="closeSidebarAndAccordions">
                                        <div class="icon">
                                            <IconHome
                                                class="dashboard-icon normal-icon" />
                                            <IconHomeActive
                                                class="dashboard-icon active-icon" />
                                        </div>
                                        <span
                                            class="text-white"
                                            v-if="showNav"
                                            >Home</span
                                        >
                                    </router-link>
                                </div>
                                <template #popper>
                                    <div class="dropdown-container">
                                        <div class="dropdown">
                                            <span class="text-white">Home</span>
                                        </div>
                                    </div>
                                </template>
                            </VDropdown>
                            <VDropdown
                                class="my-custom-theme"
                                :triggers="['hover', 'focus']"
                                placement="right"
                                popperClass="my-custom-theme"
                                :distance="6">
                                <div class="info cursor-pointer">
                                    <router-link
                                        to="/overview-main"
                                        v-if="hasActiveRoute('overviewMain')"
                                        class="link flex gap-2"
                                        :class="{
                                            'link-show-text': showNav,
                                            'w-full': !showNav,
                                        }"
                                        @click="closeSidebarAndAccordions">
                                        <div class="icon">
                                            <IconGraph
                                                class="dashboard-icon normal-icon" />
                                            <IconGraphActive
                                                class="dashboard-icon active-icon" />
                                        </div>
                                        <span
                                            class="text-white"
                                            v-if="showNav">
                                            Overview
                                        </span>
                                    </router-link>
                                </div>
                                <template #popper>
                                    <div class="dropdown-container">
                                        <div class="dropdown">
                                            <span class="text-white"
                                                >Overview</span
                                            >
                                        </div>
                                    </div>
                                </template>
                            </VDropdown>
                            <VDropdown
                                class="my-custom-theme"
                                :triggers="['hover', 'focus']"
                                placement="right"
                                popperClass="my-custom-theme"
                                :distance="6">
                                <div class="info cursor-pointer">
                                    <router-link
                                        v-if="hasActiveRoute('users')"
                                        to="/home3"
                                        class="link flex gap-2"
                                        :class="{
                                            'link-show-text': showNav,
                                            'w-full': !showNav,
                                        }"
                                        @click="closeSidebarAndAccordions">
                                        <div class="icon">
                                            <IconMainUsers
                                                class="dashboard-icon normal-icon" />
                                            <IconMainUsersActive
                                                class="dashboard-icon active-icon" />
                                        </div>
                                        <span
                                            class="text-white"
                                            v-if="showNav">
                                            Users
                                        </span>
                                    </router-link>
                                </div>
                                <template #popper>
                                    <div class="dropdown-container">
                                        <div class="dropdown">
                                            <span class="text-white"
                                                >Users</span
                                            >
                                        </div>
                                    </div>
                                </template>
                            </VDropdown>
                            <VDropdown
                                class="my-custom-theme"
                                :triggers="['hover', 'focus']"
                                placement="right"
                                popperClass="my-custom-theme"
                                :distance="6">
                                <div class="info cursor-pointer">
                                    <router-link
                                        v-if="hasActiveRoute('settings')"
                                        to="/home2"
                                        class="link flex gap-2"
                                        :class="{
                                            'link-show-text': showNav,
                                            'w-full': !showNav,
                                        }"
                                        @click="closeSidebarAndAccordions">
                                        <div class="icon">
                                            <IconSetting
                                                class="dashboard-icon normal-icon" />
                                            <IconSetting
                                                class="dashboard-icon active-icon" />
                                        </div>
                                        <span
                                            class="text-white"
                                            v-if="showNav">
                                            Setting
                                        </span>
                                    </router-link>
                                </div>
                                <template #popper>
                                    <div class="dropdown-container">
                                        <div class="dropdown">
                                            <span class="text-white"
                                                >Setting</span
                                            >
                                        </div>
                                    </div>
                                </template>
                            </VDropdown>
                        </div>
                        <VDropdown
                            class="my-custom-theme"
                            :triggers="['hover', 'focus']"
                            placement="right"
                            popperClass="my-custom-theme"
                            :distance="6">
                            <button
                                class="logout link"
                                @click="openLogoutModal">
                                <IconLogout />
                                <span v-if="showNav">Logout</span>
                            </button>
                            <template #popper>
                                <div class="dropdown-container">
                                    <div class="dropdown">
                                        <span class="text-white">Logout</span>
                                    </div>
                                </div>
                            </template>
                        </VDropdown>
                    </div>
                </div>
            </nav>
        </div>

        <nav
            ref="sideNav"
            class="hide-scrollbar"
            v-if="!$route.meta.mainSidebar">
            <div class="logo text-[18px] font-[700]">
                {{ store.project?.name }}
            </div>
            <div class="menu">
                <router-link
                    to="/overview"
                    v-if="hasActiveRoute('overview')"
                    class="link"
                    @click="closeSidebarAndAccordions">
                    <div class="icon">
                        <IconOverview class="dashboard-icon normal-icon" />
                        <IconOverviewActive
                            class="dashboard-icon active-icon" />
                    </div>
                    <span>Overview</span>
                </router-link>
                <div
                    v-for="(
                        itemValue, itemKey, itemIndex
                    ) in menuAccordionItems"
                    :key="itemIndex"
                    class="accordion"
                    :class="{ 'open-accordion': accordionsStatus[itemIndex] }">
                    <button
                        @click="toggleAccordion(itemIndex)"
                        class="heading">
                        <div class="icon">
                            <component
                                :is="itemValue.icon"
                                class="dashboard-icon"
                                :class="{
                                    hidden: accordionsStatus[itemIndex],
                                }" />
                            <component
                                :is="itemValue.activeIcon"
                                class="dashboard-icon"
                                :class="{
                                    hidden: !accordionsStatus[itemIndex],
                                }" />
                        </div>
                        <span>{{ itemKey }}</span>
                        <IconChevron
                            class="chevron-icon"
                            :color="
                                accordionsStatus[itemIndex]
                                    ? 'rgba(var(--black))'
                                    : 'rgba(var(--black) / 0.5)'
                            " />
                    </button>
                    <div class="body">
                        <ul>
                            <li
                                v-for="subitem in itemValue.subMenu"
                                :key="subitem.label"
                                v-show="hasActiveRoute(subitem.name)">
                                <RouterLink
                                    :to="{ path: subitem.path }"
                                    @click="closeSidebar">
                                    <div class="circle"></div>
                                    <span>{{ subitem.label }}</span>
                                </RouterLink>
                            </li>
                        </ul>
                    </div>
                </div>
                <router-link
                    to="/allocation"
                    v-if="hasActiveRoute('allocation')"
                    class="link"
                    @click="closeSidebarAndAccordions">
                    <div class="icon">
                        <IconAllocation class="dashboard-icon normal-icon" />
                        <IconAllocationActive
                            class="dashboard-icon active-icon" />
                    </div>
                    <span>Allocations</span>
                </router-link>
                <router-link
                    to="/inventory"
                    v-if="hasActiveRoute('inventory')"
                    class="link"
                    @click="closeSidebarAndAccordions">
                    <div class="icon">
                        <IconInventory class="dashboard-icon normal-icon" />
                        <IconInventoryActive
                            class="dashboard-icon active-icon" />
                    </div>
                    <span>Inventory</span>
                </router-link>
                <!-- Worksheets -->
                <router-link
                    to="/worksheets"
                    v-if="hasActiveRoute('worksheets')"
                    class="link"
                    @click="closeSidebarAndAccordions">
                    <div class="icon">
                        <IconTransactions class="dashboard-icon normal-icon" />
                        <IconTransactionsAction
                            class="dashboard-icon active-icon" />
                    </div>
                    <span>Worksheets</span>
                </router-link>
                <router-link
                    to="/sales-tracker"
                    class="link"
                    @click="closeSidebarAndAccordions">
                    <div class="icon">
                        <IconSales class="dashboard-icon normal-icon" />
                        <IconSalesActive class="dashboard-icon active-icon" />
                    </div>
                    <span>Sales Tracker</span>
                </router-link>
                <div
                    v-for="(
                        itemValue, itemKey, itemIndex
                    ) in menuAccordionItemsReporting"
                    :key="itemIndex"
                    class="accordion"
                    :class="{
                        'open-accordion': accordionsStatusReporting[itemIndex],
                    }"
                    v-show="hasActiveRoute('reporting')">
                    <button
                        @click="toggleAccordionReporting(itemIndex)"
                        class="heading">
                        <div class="icon">
                            <component
                                :is="itemValue.icon"
                                class="dashboard-icon"
                                :class="{
                                    hidden: accordionsStatusReporting[
                                        itemIndex
                                    ],
                                }" />
                            <component
                                :is="itemValue.activeIcon"
                                class="dashboard-icon"
                                :class="{
                                    hidden: !accordionsStatusReporting[
                                        itemIndex
                                    ],
                                }" />
                        </div>
                        <span>{{ itemKey }}</span>
                        <IconChevron
                            class="chevron-icon"
                            :color="
                                accordionsStatusReporting[itemIndex]
                                    ? 'rgba(var(--black))'
                                    : 'rgba(var(--black) / 0.5)'
                            " />
                    </button>
                    <div class="body">
                        <ul>
                            <li
                                v-for="subitem in itemValue.subMenu"
                                :key="subitem.label">
                                <RouterLink
                                    :to="{ path: subitem.path }"
                                    @click="closeSidebar">
                                    <div class="circle"></div>
                                    <span>{{ subitem.label }}</span>
                                </RouterLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    </aside>
    <ConfirmDialogue
        :is-open="isLogoutModalOpen"
        @cancel="closeLogoutModal"
        @confirm="myLogout"
        title="Log out"
        :icon="IconLogoutBlack"
        message="Are you sure you want to log out?">
    </ConfirmDialogue>
</template>

<script setup>
import { ref, inject, onMounted } from 'vue';
import { onClickOutside } from '@vueuse/core';
import IconFinishes from '../icons/sidebar/IconFinishes';
import IconFinishesActive from '../icons/sidebar/IconFinishesActive';
import IconChevron from '../icons/IconChevron.vue';
import IconContractor from '../icons/sidebar/IconContractor';
import IconContractorActive from '../icons/sidebar/IconContractorActive';
import IconLogout from '../icons/sidebar/IconLogout.vue';
import IconLogoutBlack from '../icons/sidebar/IconLogoutBlack.vue';
import { useCookies } from 'vue3-cookies';
import ConfirmDialogue from './ConfirmDialogue.vue';
import { useRoute } from 'vue-router';
import { logout } from '@/services/axios/auth/logout.service';
import IconOverview from '../icons/sidebar/IconOverview.vue';
import IconOverviewActive from '../icons/sidebar/IconOverviewActive.vue';
import IconAllocation from '../icons/sidebar/IconAllocation.vue';
import IconAllocationActive from '../icons/sidebar/IconAllocationActive.vue';
import IconInventoryActive from '../icons/sidebar/IconInventoryActive.vue';
import IconInventory from '../icons/sidebar/IconInventory.vue';
import IconTransactionsAction from '../icons/sidebar/IconTransactionsAction.vue';
import IconTransactions from '../icons/sidebar/IconTransactions.vue';
import IconReporting from '../icons/sidebar/IconReporting.vue';
import IconReportingActive from '../icons/sidebar/IconReportingActive.vue';
import IconSales from '../icons/sidebar/IconSales.vue';
import IconSalesActive from '../icons/sidebar/IconSalesActive.vue';
import IconHomeActive from '../icons/sidebar/IconHomeActive.vue';
import IconHome from '../icons/sidebar/IconHome.vue';
import IconSetting from '../icons/sidebar/IconSetting.vue';
import IconMainUsersActive from '../icons/sidebar/IconMainUsersActive.vue';
import IconMainUsers from '../icons/sidebar/IconMainUsers.vue';
import IconGraphActive from '../icons/sidebar/IconGraphActive.vue';
import IconGraph from '../icons/sidebar/IconGraph.vue';
import { useStore } from '@/store/loading.js';

const store = useStore();
const isLogoutModalOpen = ref(false);

function closeLogoutModal() {
    isLogoutModalOpen.value = false;
}

function openLogoutModal() {
    isLogoutModalOpen.value = true;
}

const props = defineProps({
    isSidebarOpen: Boolean,
});

const showNav = ref(false);
const mouseoverNav = bool => {
    if (bool) {
        showNav.value = true;
    }
};

const emits = defineEmits(['close-sidebar']);

const { cookies } = useCookies();

async function myLogout() {
    await logout();
    cookies.remove('token');
    localStorage.removeItem('user');
    location.reload();
}

const menuAccordionItems = {
    'Project Content': {
        icon: IconFinishes,
        activeIcon: IconFinishesActive,
        subMenu: [
            {
                name: 'models',
                label: 'Models',
                path: '/project-content/models',
            },
            {
                name: 'suites',
                label: 'Suites',
                path: '/project-content/suites',
            },
            {
                name: 'neighbourhood',
                label: 'Neighbourhood',
                path: '/project-content/neighbourhood',
            },
            {
                name: 'gallery',
                label: 'Gallery',
                path: '/project-content/gallery-categories',
            },
            {
                name: 'builder-stories',
                label: 'Team',
                path: '/project-content/builder-stories',
            },
            {
                name: 'amenities-map',
                label: 'Project Amenities',
                path: '/project-content/amenities-map',
            },
        ],
    },
};

const menuAccordionItemsReporting = {
    Reporting: {
        icon: IconFinishes,
        activeIcon: IconFinishesActive,
        subMenu: [
            {
                label: 'Traffic Report',
                path: '/reporting/Traffic-Report',
            },
            {
                label: 'Registration Report',
                path: '/reporting/Registration-Report',
            },
            {
                label: 'Download Report',
                path: '/reporting/Download-Report',
            },
            {
                label: 'Visual Summaries',
                path: '/reporting/Visual-Summaries',
            },
        ],
    },
};

const accordionsStatus = ref([false]); // close
const accordionsStatusReporting = ref([false]); // close

const route = useRoute();

function openRelatedAccordion() {
    const path = route.path;
    if (path.includes('project-content')) {
        accordionsStatus.value = [true];
    } else {
        accordionsStatus.value = [false];
    }
}

function openRelatedAccordionReporting() {
    const path = route.path;
    if (path.includes('reporting')) {
        accordionsStatusReporting.value = [true];
    } else {
        accordionsStatusReporting.value = [false];
    }
}

const settings = ref([]);
onMounted(() => {
    openRelatedAccordion();
    openRelatedAccordionReporting();
    settings.value = JSON.parse(cookies.get('settings'));
});

const sideNav = ref(null);

const closeSidebar = () => {
    emits('close-sidebar');
};

const closeSidebarAndAccordions = () => {
    accordionsStatus.value = [false, false, false];
    accordionsStatusReporting.value = [false, false, false];
    closeSidebar();
};

onClickOutside(sideNav, () => {
    if (props.isSidebarOpen) {
        emits('close-sidebar');
    }
});

function toggleAccordion(index) {
    accordionsStatusReporting.value = [false, false, false];
    accordionsStatus.value = accordionsStatus.value.map((status, i) =>
        i === index ? !status : false
    );
}

function toggleAccordionReporting(index) {
    accordionsStatus.value = [false, false, false];
    accordionsStatusReporting.value = accordionsStatusReporting.value.map(
        (status, i) => (i === index ? !status : false)
    );
}

function hasActiveRoute(routeName) {
    if (!settings.value) {
        return false;
    }

    return settings.value.find(item => item.name == routeName && item.is_active)
        ? true
        : false;
}
</script>

<style lang="scss" scoped>
.dropdown {
    width: fit-content;
    background: #1c1c1c;
    color: #ffffff;
    padding: 8px 12px;
    border-radius: 6px;
    box-shadow: 0px 2px 8px 0px #00000040;
}

aside.wrapper {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    z-index: 30;
    inset: 0;
    transition: all 0.4s;
    display: flex;

    @media (width > 1024px) {
        visibility: visible;
        opacity: 1;
        z-index: 0;
        height: 100svh;

        &.wrapper1 {
            width: 356px;

            > div {
                background-color: white;
            }
        }

        &.wrapper2 {
            width: 76px;
            z-index: 100;
            background-color: rgb(var(--gray-100));
        }
    }

    .main-side {
        overflow: auto;
        transition: all 0.4s;
        background: rgba(49, 53, 59, 1);
        height: 100%;
        border-radius: 0 24px 24px 0;
        padding: 0;
        display: flex;
        flex-direction: column;

        .menu {
            gap: 0 !important;
            padding: 0 16px;

            .link {
                width: 100%;

                .icon {
                    margin-left: 0px;
                }
            }
        }

        .menu .link:hover {
            font-weight: 700 !important;
            color: rgb(var(--black)) !important;
            background: rgba(255, 255, 255, 0.1) !important;

            .normal-icon {
                opacity: 0 !important;
                visibility: hidden !important;
            }

            .active-icon {
                opacity: 1 !important;
                visibility: visible !important;
            }
        }

        .menu {
            .link {
                &.router-link-active {
                    font-weight: 700;
                    color: rgb(var(--black));
                    background: rgba(255, 255, 255, 0.1) !important;
                }
            }
        }
    }

    .hide-main-side {
        width: 76px;
    }

    .show-main-side {
        width: 280px;
    }

    nav {
        overflow: auto;
        transition: all 0.4s;
        translate: -300px;
        width: min(75%, 300px);
        background-color: rgb(var(--white));
        height: 100%;
        border-radius: 0 24px 24px 0;
        padding: 0 16px;
        display: flex;
        flex-direction: column;

        @media (width > 1024px) {
            translate: 0;
            width: 280px;
        }

        .logo {
            padding: 60px 4px 76px;
        }

        .logo.logo-aside {
            padding: 60px 4px 72px;
        }

        .menu {
            display: flex;
            flex-direction: column;
            gap: 12px;
            margin-bottom: 8px;

            .accordion {
                .heading {
                    display: flex;
                    align-items: center;
                    transition: all 0.4s;
                    gap: 16px;
                    font-weight: 400;
                    padding: 10px 8px;
                    border-radius: 4px;
                    width: 100%;
                    color: rgba(var(--black) / 0.5);

                    .icon {
                        position: relative;
                        // background-color: red;
                        height: 24px;
                        width: 24px;

                        .dashboard-icon {
                            position: absolute;
                            width: 24px;
                            top: 0;
                            left: 0;
                            transition: all 0.4s;
                        }
                    }

                    .chevron-icon {
                        width: 18px;
                        margin-left: auto;
                        rotate: 180deg;
                        transition: all 0.4s;
                    }
                }

                .body {
                    padding: 0px 0px;
                    max-height: 0px;
                    overflow: hidden;
                    transition: all 0.4s;

                    ul {
                        padding-left: 40px;
                        margin-top: 10px;

                        li {
                            a {
                                padding: 10px 16px;
                                border-radius: 4px;
                                display: flex;
                                align-items: center;
                                gap: 16px;

                                .circle {
                                    width: 10px;
                                    aspect-ratio: 1;
                                    border-radius: 50%;
                                    background-color: rgba(var(--black) / 0.2);
                                }

                                span {
                                    transition: all 0.4s;
                                    color: rgba(var(--black) / 0.5);
                                }
                            }

                            a.active,
                            a.router-link-active,
                            a.router-link-exact-active {
                                background-color: rgba(var(--blue) / 0.1);

                                span {
                                    color: rgba(var(--black) / 1);
                                    font-weight: bold;
                                }

                                .circle {
                                    background-color: rgb(var(--black));
                                }
                            }
                        }
                    }
                }
            }

            .open-accordion {
                .heading {
                    background-color: rgba(var(--blue) / 0.1);

                    span {
                        font-weight: 700;
                        color: rgb(var(--black));
                    }

                    .chevron-icon {
                        rotate: 0deg;
                    }
                }

                .body {
                    max-height: 300px;
                }
            }

            .link {
                display: flex;
                align-items: center;
                transition: all 0.4s;
                gap: 16px;
                font-weight: 400;
                padding: 10px 8px;
                border-radius: 4px;
                width: 100%;
                color: rgba(var(--black) / 0.5);

                .icon {
                    position: relative;
                    // background-color: red;
                    height: 24px;
                    width: 24px;

                    .dashboard-icon {
                        position: absolute;
                        width: 24px;
                        top: 0;
                        left: 0;
                        transition: all 0.4s;
                    }

                    .normal-icon {
                        opacity: 1;
                        visibility: visible;
                    }

                    .active-icon {
                        opacity: 0;
                        visibility: hidden;
                    }
                }

                .chevron-icon {
                    width: 18px;
                    margin-left: auto;
                    rotate: 180deg;
                    transition: all 0.4s;
                }

                &.router-link-active {
                    font-weight: 700;
                    color: rgb(var(--black));
                    background-color: rgba(var(--blue) / 0.1);

                    .normal-icon {
                        opacity: 0;
                        visibility: hidden;
                    }

                    .active-icon {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
        }

        .logout {
            display: flex;
            align-items: center;
            gap: 16px;
            padding: 10px 8px;
            margin-top: auto;
            // margin-bottom: 52px;
            width: fit-content;
        }
    }
}

aside.wrapper.open {
    opacity: 1;
    visibility: visible;
    background-color: rgba(var(--black) / 0.2);
    backdrop-filter: blur(4px);

    nav {
        translate: 0;
    }
}

.hidden {
    opacity: 0;
    visibility: hidden;
}
</style>
